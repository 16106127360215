<template>
    <div class="cart-single">
        <div class="row content-form">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <div class="categories-content">
                    <h4 class="shop-title">Korpa</h4>

                    <div class="cart-content">
                        <div class="">
                            <table
                                class="shopping-cart-content shopping-cart-table"
                            >
                                <tr class="title">
                                    <td class="product-name">
                                        Naziv proizvoda
                                    </td>
                                    <td class="price">Cena po kom.</td>
                                    <td class="quantity-item">Količina</td>
                                    <td class="total">Ukupno</td>
                                    <td class="delete-item"></td>
                                </tr>
                                <cart-row
                                    v-bind:item="[item, index]"
                                    v-for="(item,
                                    index) in cart_data.product_list"
                                    :key="item.cart_id"
                                ></cart-row>
                            </table>
                        </div>
                    </div>
                    <!--                    <div v-else class="cart-content">-->
                    <!--                        <h3 class="">Vaša korpa je prazna</h3>-->
                    <!--                    </div>-->
                    <div class="cart-single__action">
                        <a :href="APP_URL" class="btn btn-block btn-primary"
                            >Nastavite kupovinu</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                <div class="proceed-checkout">
                    <h4 class="main-title d-none d-md-block">
                        Nastavite sa plaćanjem
                    </h4>
                    <div class="content">
                        <h5 class="title">Ukupno u korpi</h5>
                        <div class="info-checkout">
                            <span class="text">Ukupno proizvoda: </span
                            ><span class="item">{{
                                cart_data.total | currency
                            }}</span>
                        </div>
                        <div class="info-checkout shipping">
                            <!--<span class="text">Isporuka:</span><span class="item">0</span>-->
                            <span class="text"> <b>+ dostava</b></span>
                        </div>
                        <div class="total-checkout">
                            <span class="text">Ukupno </span
                            ><span class="price">{{
                                cart_data.total | currency
                            }}</span>
                        </div>
                        <div class="group-button">
                            <a
                                @click.prevent="submitCart()"
                                :href="APP_URL + '/checkout'"
                                class="btn btn-block btn-primary"
                                >Potvrdi</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
import CartRowComponent from "./CartRowComponent";
export default {
    name: "CartComponent",
    props: ["parent_cart_data"],
    components: {
        "cart-row": CartRowComponent
    },
    data() {
        return {
            APP_URL: "",
            cart_data: this.parent_cart_data
        };
    },
    methods: {
        submitCart() {
            const vm = this;

            var data = [];
            for (var i = 0; i < this.cart_data.product_list.length; i++) {
                data.push({
                    product_id: this.cart_data.product_list[i].id,
                    additions: this.cart_data.product_list[i].additions,
                    amount: this.cart_data.product_list[i].amount,
                    size_id: this.cart_data.product_list[i].selected_size,
                    toppings: this.cart_data.product_list[i].selected_toppings
                });
            }

            axios
                .put(APP_URL + "/api/cart", { cart: data })
                .then(function(response) {

                    window.location.href = vm.APP_URL + "/placanje";
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        // console.log("APP_URL: ", APP_URL);
        this.APP_URL = APP_URL;
        // console.log("this.cart_data.product_list: ",this.cart_data.product_list);
        const vm = this;
        EventBus.$on("removedItemFromCArt", data => {
            vm.cart_data = data;
        });
        EventBus.$on("cartDataEvent", data => {
            vm.cart_data = data;
        });
        EventBus.$on("cartProductUpdateTotalEvent", data => {
            this.cart_data.product_list[data.index].product_total = data.total;
        });
    },
    computed: {
        cart_total: function() {
            // cart_data.product_list
            let val = 0;
            for (var i = 0; i < this.cart_data.product_list.length; i++) {
                val += this.cart_data.product_list[i].product_total;
            }
            return Math.round(val * 100) / 100;
        }
    }
};
</script>

<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="container">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="onSubmit">
                            <div class="modal-container p-0">
                                <div>
                                    <div class="modal-header d-block">
                                        <div class="modal-header-element">
                                            <h2 class="modal-product-title">
                                                {{ product.title }}
                                            </h2>
                                            <p class="modal-product-desc">
                                                {{ product.description }}
                                            </p>
                                            <button
                                                type="button"
                                                class="btn modal-header-close-btn"
                                                @click="hide"
                                            >
                                                <span
                                                    class="icon-close"
                                                    aria-hidden="true"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <div
                                            v-if="sizes.length > 0"
                                            class="modal-element option-element"
                                        >
                                            <h3
                                                class="modal-product__sub-title mb-0"
                                            >
                                                Opcije
                                                <span class="required-mark"
                                                    >*</span
                                                >
                                            </h3>
                                            <p
                                                class="modal-product__sub-title-desc"
                                            >
                                                <span class="required-mark"
                                                    >*</span
                                                >
                                                <span class="text-muted small"
                                                    >Opcija je obavezna</span
                                                >
                                            </p>
                                            <ValidationProvider
                                                name="Veličina"
                                                rules="required"
                                                v-slot="{ errors }"
                                            >
                                                <div class="">
                                                    <div>
                                                        <ul
                                                            class="modal-product__option-list row"
                                                        >
                                                            <li
                                                                v-for="size in sizes"
                                                                :key="size.id"
                                                                class="col-md-6"
                                                            >
                                                                <div
                                                                    class="custom-control custom-radio option-item"
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        :id="
                                                                            'size_' +
                                                                                size.id
                                                                        "
                                                                        v-model="
                                                                            selected_size
                                                                        "
                                                                        :value="
                                                                            size.id
                                                                        "
                                                                        class="custom-control-input"
                                                                    />
                                                                    <label
                                                                        class="custom-control-label"
                                                                        :for="
                                                                            'size_' +
                                                                                size.id
                                                                        "
                                                                    >
                                                                        <span
                                                                            class="option-item__name"
                                                                            >{{
                                                                                size.name
                                                                            }}</span
                                                                        >
                                                                        <span
                                                                            class="option-item__spacer"
                                                                        ></span>

                                                                        <div
                                                                            v-if="
                                                                                size.original_price !==
                                                                                    size.price
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="option-item__price __old mr-3"
                                                                                >{{
                                                                                    size.original_price
                                                                                        | currency
                                                                                }}</span
                                                                            >
                                                                            <span
                                                                                class="option-item__price"
                                                                                >{{
                                                                                    size.price
                                                                                        | currency
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                        <div
                                                                            v-else
                                                                        >
                                                                            <span
                                                                                class="option-item__price"
                                                                                >{{
                                                                                    size.price
                                                                                        | currency
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <span
                                                    class="modal-input-error "
                                                    >{{ errors[0] }}</span
                                                >
                                            </ValidationProvider>
                                        </div>

                                        <div
                                            v-if="
                                                toppings_free.length > 0 ||
                                                    toppings_paid.length > 0
                                            "
                                            class="modal-element option-element"
                                        >
                                            <h3
                                                class="modal-product__sub-title"
                                            >
                                                Dodaci
                                            </h3>
                                            <ul
                                                class="modal-product__option-list row"
                                            >
                                                <li
                                                    class="col-md-6"
                                                    v-for="topping in toppings_free"
                                                    :key="topping.id"
                                                >
                                                    <div
                                                        class="custom-control custom-checkbox option-item"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            v-model="
                                                                selected_toppings
                                                            "
                                                            :value="topping.id"
                                                            :id="
                                                                'topping_' +
                                                                    topping.id
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            :for="
                                                                'topping_' +
                                                                    topping.id
                                                            "
                                                        >
                                                            <span
                                                                class="option-item__name"
                                                                >{{
                                                                    topping.name
                                                                }}</span
                                                            >
                                                            <span
                                                                class="option-item__spacer"
                                                            ></span>
                                                            <span
                                                                class="option-item__price"
                                                                >{{
                                                                    topping.price
                                                                        | currency
                                                                }}</span
                                                            >
                                                        </label>
                                                    </div>
                                                </li>
                                                <li
                                                    class="col-md-6"
                                                    v-for="topping in toppings_paid"
                                                    :key="topping.id"
                                                >
                                                    <div
                                                        class="custom-control custom-checkbox option-item"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            v-model="
                                                                selected_toppings
                                                            "
                                                            :value="topping.id"
                                                            :id="
                                                                'topping_' +
                                                                    topping.id
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            :for="
                                                                'topping_' +
                                                                    topping.id
                                                            "
                                                        >
                                                            <span
                                                                class="option-item__name"
                                                                >{{
                                                                    topping.name
                                                                }}</span
                                                            >
                                                            <span
                                                                class="option-item__spacer"
                                                            ></span>
                                                            <span
                                                                class="option-item__price"
                                                                >{{
                                                                    topping.price
                                                                        | currency
                                                                }}</span
                                                            >
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="modal-element">
                                            <ValidationProvider
                                                name="Napomena"
                                                rules="max:250"
                                                v-slot="{ errors }"
                                            >
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="additions"
                                                            >Napomena</label
                                                        >
                                                        <textarea
                                                            id="additions"
                                                            class="form-control"
                                                            v-model="additions"
                                                            rows="2"
                                                        ></textarea>
                                                        <div
                                                            class="text-muted small"
                                                        >
                                                            Unesite dodatke za
                                                            naručeni proizvod
                                                            ili napomene o
                                                            pripremi / isporuci
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    class="modal-input-error "
                                                    >{{ errors[0] }}</span
                                                >
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div
                                            class="modal-element d-md-flex justify-content-between text-center"
                                        >
                                            <ValidationProvider
                                                name="Količina"
                                                rules="positive|numeric"
                                                v-slot="{ errors }"
                                            >
                                                <div
                                                    class="modal-qty mb-md-0 mb-2"
                                                >
                                                    <div class="quantity">
                                                        <div
                                                            class="group-quantity-button"
                                                        >
                                                            <button
                                                                type="button"
                                                                @click="
                                                                    decreaseQuantity()
                                                                "
                                                                class="minus btn"
                                                            >
                                                                <span
                                                                    class="icon-minus"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            </button>
                                                            <input
                                                                v-model="amount"
                                                                type="text"
                                                                :id="
                                                                    'quantity_' +
                                                                        product.id
                                                                "
                                                                class="input-text qty text"
                                                                step="1"
                                                                min="1"
                                                                max=""
                                                                name="quantity"
                                                                value="1"
                                                                title="Količina"
                                                                size="4"
                                                                pattern="[0-9]*"
                                                                inputmode="numeric"
                                                            />
                                                            <button
                                                                type="button"
                                                                @click="
                                                                    increaseQuantity(
                                                                        product.id
                                                                    )
                                                                "
                                                                class="plus btn"
                                                            >
                                                                <span
                                                                    class="icon-plus"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <span
                                                        class="modal-input-error ml-3"
                                                        v-if="errors[0]"
                                                        >Količina mora biti
                                                        pozitivan broj</span
                                                    >
                                                    <label
                                                        :for="
                                                            'quantity_' +
                                                                product.id
                                                        "
                                                        >Količina</label
                                                    >
                                                </div>
                                            </ValidationProvider>
                                            <div
                                                class="modal-form-actions ml-auto"
                                            >
                                                <div
                                                    :class="animation_class"
                                                    v-if="isAddedToCart"
                                                    class="modal-form-actions__info rounded-info"
                                                >
                                                    <i class="fas fa-check"></i>
                                                </div>
                                                <button
                                                    :disabled="invalid"
                                                    v-if="is_edit"
                                                    type="submit"
                                                    class="btn btn-primary btn-lg"
                                                >
                                                    Izmeni
                                                </button>
                                                <button
                                                    :disabled="invalid"
                                                    v-else
                                                    type="submit"
                                                    class="btn btn-primary btn-lg"
                                                >
                                                    Dodaj u korpu
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="loadingOverlay"
                                    class="loading-overlay"
                                >
                                    <span class="loading-icon icon-cog"></span>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { EventBus } from "../event-bus.js";

export default {
    name: "SingleProductModal",
    props: ["modal_data"],
    data() {
        return {
            // variable that shows/hides modal
            animated: false,
            animation_class: "",
            isSubmitted: false,
            isAddedToCart: false,
            loadingOverlay: false,

            isModalAdding: false,
            isModalEditing: false,

            product: this.modal_data,
            amount: 1,
            additions: null,

            // TODO change sizes, toppings_free, toppings_paid, selected  to dynamic values from product
            sizes: [],

            /*sizes: [
                    {"id": 1, "name": "28 cm", "price": 395},
                    {"id": 2, "name": "33 cm", "price": 595},
                    {"id": 3, "name": "51 cm", "price": 995}
                ],*/

            toppings_free: [],
            toppings_paid: [],

            selected_size: null,
            selected_toppings: [],

            visible: false,

            error: {
                amount: null
            }
        };
    },
    mounted() {
        // console.log("this.product: ", this.product.toppings_free);
        this.toppings_free = this.product.toppings_free;
        this.toppings_paid = this.product.toppings_paid;
        this.sizes = this.product.available_sizes;
        if (this.is_edit) {
            this.amount = this.product.amount;
            this.additions = this.product.additions;
            this.selected_size = this.product.selected_size;
            this.selected_toppings = this.product.selected_toppings;
        } else {
            if (this.sizes.length > 0) {
                // Ako je daodavanj i ako ima velicive, selektuj prvu velicinu
                this.selected_size = this.sizes[0].id;
            }
        }
    },
    computed: {
        is_edit: function() {
            return this.product.cart_id !== undefined;
        }
    },
    methods: {
        get_params() {
            var data = {
                amount: this.amount,
                size_id: this.selected_size,
                toppings: this.selected_toppings,
                additions: this.additions
            };

            if (!this.is_edit) {
                data.product_id = this.product.id;
            }

            return data;
        },
        onSubmit() {
            if (this.is_edit) {
                this.editProductFromCart();
            } else this.addToCart();
        },
        isPositiveInteger(n) {
            return n >>> 0 === parseFloat(n);
        },
        increaseQuantity() {
            if (this.isPositiveInteger(this.amount)) {
                this.amount++;
            } else {
                this.amount = 1;
            }
        },
        decreaseQuantity() {
            if (this.isPositiveInteger(this.amount)) {
                if (this.amount > 1) {
                    this.amount--;
                } else {
                    this.amount = 1;
                }
            } else {
                this.amount = 1;
            }
        },
        // Display with animation  fa-check icon i green & remove it after some time also with animation
        animateinfo() {},

        // Empty form so it can not be posted again
        resetFrom() {
            this.additions = null;
        },

        editProductFromCart() {
            const vm = this;

            this.$http
                .put(
                    APP_URL + "/api/cart/" + this.product.cart_id,
                    this.get_params()
                )
                .then(function(response) {
                    vm.$http
                        .get(APP_URL + "/api/cart")
                        .then(response => {
                            vm.cartData = response.data;

                            EventBus.$emit("cartDataEvent", response.data);
                        })
                        .catch(error => {
                            // console.log(error);
                        });
                    vm.after("Proizvod je izmenjen u korpi");
                })
                .catch(function(error) {
                    vm.$toastr.e("Došlo je do greške");
                    // hide overlay even if error
                    vm.loadingOverlay = false;
                    vm.hide();
                    console.log(error);
                });
        },

        // Submit form - mark as submit started - isSubmitted, load overlay until ajax return some response,
        addToCart() {
            const vm = this;

            vm.loadingOverlay = true;

            this.$http
                .post(APP_URL + "/api/cart", this.get_params())
                .then(function(response) {
                    vm.$http
                        .get(APP_URL + "/api/cart")
                        .then(response => {
                            EventBus.$emit("cartDataEvent", response.data);
                            vm.hide();


                            vm.hide();
                            //  fbq("track", "AddToCart", {
                            //     value: vm.product.price,
                            //     currency: "RSD",
                            //     content_type: "product",
                            //     content_ids: [vm.product.id]
                            // });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    vm.after("Proizvod je dodat u korpu");
                })
                .catch(function(error) {
                    vm.$toastr.e("Došlo je do greške");
                    // hide overlay even if error
                    vm.loadingOverlay = false;
                    vm.hide();
                    console.log(error);
                });
        },
        after(message) {
            // remove overlay
            this.loadingOverlay = false;

            // reset form state so nex time it can be clicked and validated
            this.isSubmitted = false;

            // Used for displaying animated  fa-check icon i green circle
            this.animateinfo();

            // Reset form
            this.resetFrom();

            this.$toastr.s(message);
        },
        showCartModal() {},
        hide() {
            // method for closing modal
            this.visible = false;

            // Used for making body tag not scrollable when modal is open & vice versa
            EventBus.$emit("moduleIsOpen", false);
            EventBus.$emit("singleProductModalEvent", this.visible);
        },
        confirm() {
            // confirm code will be here soon(TM)
        }
    }
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"container"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"modal-container p-0"},[_c('div',[_c('div',{staticClass:"modal-header d-block"},[_c('div',{staticClass:"modal-header-element"},[_c('h2',{staticClass:"modal-product-title"},[_vm._v("\n                                            "+_vm._s(_vm.product.title)+"\n                                        ")]),_vm._v(" "),_c('p',{staticClass:"modal-product-desc"},[_vm._v("\n                                            "+_vm._s(_vm.product.description)+"\n                                        ")]),_vm._v(" "),_c('button',{staticClass:"btn modal-header-close-btn",attrs:{"type":"button"},on:{"click":_vm.hide}},[_c('span',{staticClass:"icon-close",attrs:{"aria-hidden":"true"}})])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.sizes.length > 0)?_c('div',{staticClass:"modal-element option-element"},[_c('h3',{staticClass:"modal-product__sub-title mb-0"},[_vm._v("\n                                            Opcije\n                                            "),_c('span',{staticClass:"required-mark"},[_vm._v("*")])]),_vm._v(" "),_c('p',{staticClass:"modal-product__sub-title-desc"},[_c('span',{staticClass:"required-mark"},[_vm._v("*")]),_vm._v(" "),_c('span',{staticClass:"text-muted small"},[_vm._v("Opcija je obavezna")])]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Veličina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{},[_c('div',[_c('ul',{staticClass:"modal-product__option-list row"},_vm._l((_vm.sizes),function(size){return _c('li',{key:size.id,staticClass:"col-md-6"},[_c('div',{staticClass:"custom-control custom-radio option-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                        _vm.selected_size
                                                                    ),expression:"\n                                                                        selected_size\n                                                                    "}],staticClass:"custom-control-input",attrs:{"type":"radio","id":'size_' +
                                                                            size.id},domProps:{"value":size.id,"checked":_vm._q(
                                                                        _vm.selected_size
                                                                    ,size.id)},on:{"change":function($event){_vm.selected_size
                                                                    =size.id}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":'size_' +
                                                                            size.id}},[_c('span',{staticClass:"option-item__name"},[_vm._v(_vm._s(size.name))]),_vm._v(" "),_c('span',{staticClass:"option-item__spacer"}),_vm._v(" "),(
                                                                            size.original_price !==
                                                                                size.price
                                                                        )?_c('div',[_c('span',{staticClass:"option-item__price __old mr-3"},[_vm._v(_vm._s(_vm._f("currency")(size.original_price)))]),_vm._v(" "),_c('span',{staticClass:"option-item__price"},[_vm._v(_vm._s(_vm._f("currency")(size.price)))])]):_c('div',[_c('span',{staticClass:"option-item__price"},[_vm._v(_vm._s(_vm._f("currency")(size.price)))])])])])])}),0)])]),_vm._v(" "),_c('span',{staticClass:"modal-input-error "},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_vm._v(" "),(
                                            _vm.toppings_free.length > 0 ||
                                                _vm.toppings_paid.length > 0
                                        )?_c('div',{staticClass:"modal-element option-element"},[_c('h3',{staticClass:"modal-product__sub-title"},[_vm._v("\n                                            Dodaci\n                                        ")]),_vm._v(" "),_c('ul',{staticClass:"modal-product__option-list row"},[_vm._l((_vm.toppings_free),function(topping){return _c('li',{key:topping.id,staticClass:"col-md-6"},[_c('div',{staticClass:"custom-control custom-checkbox option-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.selected_toppings
                                                        ),expression:"\n                                                            selected_toppings\n                                                        "}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":'topping_' +
                                                                topping.id},domProps:{"value":topping.id,"checked":Array.isArray(
                                                            _vm.selected_toppings
                                                        )?_vm._i(
                                                            _vm.selected_toppings
                                                        ,topping.id)>-1:(
                                                            _vm.selected_toppings
                                                        )},on:{"change":function($event){var $$a=
                                                            _vm.selected_toppings
                                                        ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=topping.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(
                                                            _vm.selected_toppings
                                                        =$$a.concat([$$v]))}else{$$i>-1&&(
                                                            _vm.selected_toppings
                                                        =$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{
                                                            _vm.selected_toppings
                                                        =$$c}}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":'topping_' +
                                                                topping.id}},[_c('span',{staticClass:"option-item__name"},[_vm._v(_vm._s(topping.name))]),_vm._v(" "),_c('span',{staticClass:"option-item__spacer"}),_vm._v(" "),_c('span',{staticClass:"option-item__price"},[_vm._v(_vm._s(_vm._f("currency")(topping.price)))])])])])}),_vm._v(" "),_vm._l((_vm.toppings_paid),function(topping){return _c('li',{key:topping.id,staticClass:"col-md-6"},[_c('div',{staticClass:"custom-control custom-checkbox option-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.selected_toppings
                                                        ),expression:"\n                                                            selected_toppings\n                                                        "}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":'topping_' +
                                                                topping.id},domProps:{"value":topping.id,"checked":Array.isArray(
                                                            _vm.selected_toppings
                                                        )?_vm._i(
                                                            _vm.selected_toppings
                                                        ,topping.id)>-1:(
                                                            _vm.selected_toppings
                                                        )},on:{"change":function($event){var $$a=
                                                            _vm.selected_toppings
                                                        ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=topping.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(
                                                            _vm.selected_toppings
                                                        =$$a.concat([$$v]))}else{$$i>-1&&(
                                                            _vm.selected_toppings
                                                        =$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{
                                                            _vm.selected_toppings
                                                        =$$c}}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":'topping_' +
                                                                topping.id}},[_c('span',{staticClass:"option-item__name"},[_vm._v(_vm._s(topping.name))]),_vm._v(" "),_c('span',{staticClass:"option-item__spacer"}),_vm._v(" "),_c('span',{staticClass:"option-item__price"},[_vm._v(_vm._s(_vm._f("currency")(topping.price)))])])])])})],2)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-element"},[_c('ValidationProvider',{attrs:{"name":"Napomena","rules":"max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                                var errors = ref.errors;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":"additions"}},[_vm._v("Napomena")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.additions),expression:"additions"}],staticClass:"form-control",attrs:{"id":"additions","rows":"2"},domProps:{"value":(_vm.additions)},on:{"input":function($event){if($event.target.composing){ return; }_vm.additions=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"text-muted small"},[_vm._v("\n                                                        Unesite dodatke za\n                                                        naručeni proizvod\n                                                        ili napomene o\n                                                        pripremi / isporuci\n                                                    ")])])]),_vm._v(" "),_c('span',{staticClass:"modal-input-error "},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-element d-md-flex justify-content-between text-center"},[_c('ValidationProvider',{attrs:{"name":"Količina","rules":"positive|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                                var errors = ref.errors;
return [_c('div',{staticClass:"modal-qty mb-md-0 mb-2"},[_c('div',{staticClass:"quantity"},[_c('div',{staticClass:"group-quantity-button"},[_c('button',{staticClass:"minus btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.decreaseQuantity()}}},[_c('span',{staticClass:"icon-minus",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticClass:"input-text qty text",attrs:{"type":"text","id":'quantity_' +
                                                                    _vm.product.id,"step":"1","min":"1","max":"","name":"quantity","value":"1","title":"Količina","size":"4","pattern":"[0-9]*","inputmode":"numeric"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"plus btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.increaseQuantity(
                                                                    _vm.product.id
                                                                )}}},[_c('span',{staticClass:"icon-plus",attrs:{"aria-hidden":"true"}})])])]),_vm._v(" "),(errors[0])?_c('span',{staticClass:"modal-input-error ml-3"},[_vm._v("Količina mora biti\n                                                    pozitivan broj")]):_vm._e(),_vm._v(" "),_c('label',{attrs:{"for":'quantity_' +
                                                            _vm.product.id}},[_vm._v("Količina")])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"modal-form-actions ml-auto"},[(_vm.isAddedToCart)?_c('div',{staticClass:"modal-form-actions__info rounded-info",class:_vm.animation_class},[_c('i',{staticClass:"fas fa-check"})]):_vm._e(),_vm._v(" "),(_vm.is_edit)?_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v("\n                                                Izmeni\n                                            ")]):_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v("\n                                                Dodaj u korpu\n                                            ")])])],1)])]),_vm._v(" "),(_vm.loadingOverlay)?_c('div',{staticClass:"loading-overlay"},[_c('span',{staticClass:"loading-icon icon-cog"})]):_vm._e()])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
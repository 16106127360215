<template>
    <b-modal id="address-modal" modal-class="v-boots-modal address-modal" title="Podaci o dostavi" @hide="prevent" hide-header-close hide-footer>

        <div class="modal-loader"  v-if="is_processing">
            <div class="loading-sec">

                <i class="loading-icon fa fa-cog" aria-hidden="true"></i>
            </div>
        </div>
        <form class="modal-form" @submit.prevent="submit">

            <!--            <div class="form-group">-->
            <!--                <label for="is_picked_up">Preuzimanje u lokalu-->
            <!--                    <input type="checkbox" class="form-control" v-model="form.is_picked_up" id="is_picked_up">-->
            <!--                </label>-->
            <!--            </div>-->
            <div class="modal-form-body">
                <b-form-checkbox v-model="form.is_picked_up" switch size="lg">Preuzimanje u lokalu</b-form-checkbox>
                <hr>

                <div class="form-group">
                    <label for="street">Ulica *</label>
                    <input type="text" class="form-control" v-model="form.street" id="street"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="form-group">
                    <label for="street_number">Broj *</label>
                    <input type="text" class="form-control" v-model="form.street_number" id="street_number"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="form-group">
                    <label for="city">Grad *</label>
                    <input type="text" class="form-control" v-model="form.city" id="city"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="form-group">
                    <label for="floor">Sprat *</label>
                    <input type="text" class="form-control" v-model="form.floor" id="floor"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="form-group">
                    <label for="apartment">Stan *</label>
                    <input type="text" class="form-control" v-model="form.apartment" id="apartment"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="form-group">
                    <label for="intercom">Interfon *</label>
                    <input type="text" class="form-control" v-model="form.intercom" id="intercom"
                           :disabled="form.is_picked_up" :required="!form.is_picked_up">
                </div>
                <div class="alert alert-warning" v-if="warning_text !== null">{{warning_text}}</div>
            </div>

            <div class="modal-form-footer">
                <button class="btn btn-primary btn-block" :disabled="is_processing">{{button_text}}</button>
            </div>

        </form>
    </b-modal>
</template>

<script>

    const dostavau_api = window.APP_URL + '/api/dostavau/zone-for-point';
    const google = window.google;

    export default {

        data: function () {
            return {
                form: {
                    is_picked_up: false,
                    delivery_fee: 0,
                },
                placesService: null,
                warning_text: null,
                is_processing: false,
            };
        },
        computed: {
            button_text: function () {
                return this.is_processing ? 'Molim sačekajte...' : 'Nastavi sa naručivanjem';
            }
        },
        methods: {
            place_query: function () {
                return {
                    query: this.form.street + ' ' + this.form.street_number + ', ' + this.form.city,
                    fields: ['geometry'],
                };
            },
            prevent: function (event) {
                if (event.trigger === 'backdrop') {
                    event.preventDefault();
                }
            },
            emit_delivery: function () {
                this.$emit('setaddress', {
                    is_picked_up: 0,
                    delivery_fee: this.form.delivery_fee,
                    longitude: this.form.longitude,
                    latitude: this.form.latitude,
                    street: this.form.street,
                    street_number: this.form.street_number,
                    city: this.form.city,
                    floor: this.form.floor,
                    apartment: this.form.apartment,
                    intercom: this.form.intercom,
                })
            },
            emit_pickup: function () {
                this.$emit('setaddress', {
                    is_picked_up: 1,
                    delivery_fee: 0,
                    longitude: null,
                    latitude: null,
                    street: null,
                    street_number: null,
                    city: null,
                    floor: null,
                    apartment: null,
                    intercom: null,
                })
            },
            resolve_delivery: function () {
                axios.post(APP_URL + '/api/dostavau/zone-for-point', {
                    latitude: this.form.latitude,
                    longitude: this.form.longitude
                }).then(response => {
                    if (response.data.zone !== null) {
                        this.form.delivery_fee = response.data.zone.price;
                        this.emit_delivery();
                    } else {
                        this.form.delivery_fee = 0;
                        this.emit_delivery();
                        // this.warning_text = 'Dostava na vašu adresu nije moguća.';
                    }

                    this.is_processing = false;
                })
            },
            submit: function () {
                this.warning_text = null;
                this.is_processing = true;
                var vm = this;

                this.placesService.findPlaceFromQuery(this.place_query(), function (results) {
                    if (results !== null && results.length > 0) {
                        vm.form.latitude = results[0].geometry.location.lat();
                        vm.form.longitude = results[0].geometry.location.lng();
                        vm.resolve_delivery();
                    } else {
                        vm.is_processing = false;
                        vm.form.latitude = vm.form.longitude = null;
                        vm.form.delivery_fee = 0;
                        vm.emit_delivery();
                        // vm.warning_text = 'Dostava na vašu adresu nije moguća.';
                    }
                });
            }
        },
        mounted: function () {
            this.placesService = new google.maps.places.PlacesService(document.getElementById('map'));
        },
        watch: {
            'form.is_picked_up': function () {
                if (this.form.is_picked_up) {
                    this.emit_pickup();
                    this.warning_text = null;
                    this.form.street = null;
                    this.form.street_number = null;
                    this.form.city = null;
                    this.form.floor = null;
                    this.form.apartment = null;
                    this.form.intercom = null;
                }
            }
        }
    };

</script>

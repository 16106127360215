<template>
    <div class="confirmation_sec d-inline-block">
        <div class="confirmation_sec__text" v-if="delivery_time === null">
            <p>
                Molimo vas sačekajte nekoliko trenutaka. <br />Vaša poružbina se
                obrađuje...
            </p>
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="confirmation_sec__text" v-else>
            Vaša porudžbina je potvrđena i <br />

            <span v-if="is_picked_up">
                možete je preuzeti za
                <b class="text-danger">{{ delivery_time }}</b> minuta, <br />
                <span class="pickup-address">na adresi <b>Maksima Gorkog 17a, Lokal L4 21000 Novi Sad</b></span>
            </span>
            <span v-else>
                biće na vašoj adresi za
                <b class="text-danger">{{ delivery_time }}</b> minuta.
            </span>
        </div>
        <div class="confirmation_sec__text mt-5">
            <p>
                Za sva dodatna pitanja i potvrdu porudžbine<br />možete pozvati
                <a href="tel:+381600249028" class="mr-2">
                    +381 60 0249 028&#8236;
                </a>
            </p>
        </div>
    </div>
</template>

<script>
const confirmation_api = window.APP_URL + "/api/placanje/potvrda";

export default {
    data: function() {
        return {
            interval: null,
            delivery_time: null,
            is_picked_up: 0
        };
    },
    methods: {
        confirmation_check: function() {
            axios.get(confirmation_api).then(response => {
                // console.log(response);
                this.delivery_time = response.data.delivery_time;
                this.is_picked_up = response.data.checkout.is_picked_up;
                if (this.delivery_time !== null) {
                    clearInterval(this.interval);
                }
            });
        }
    },
    created: function() {
        this.interval = setInterval(() => {
            this.confirmation_check();
        }, 5000);
    }
};
</script>

// https://www.npmjs.com/package/vue-toastr
import Toastr from 'vue-toastr';
Vue.use(Toastr, {
    defaultCloseButton: true,
});

/**
 * VEE VALIDATE
 * https://logaretm.github.io/vee-validate/
 */
// https://logaretm.github.io/vee-validate/
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import sr_Latin from 'vee-validate/dist/locale/sr_Latin.json';
import * as rules from 'vee-validate/dist/rules';
// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});


extend('positive', value => {
    return value >= 1;
});
localize('sr_Latin', sr_Latin);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);



/**
 * EMD vee-validate
 */



// https://www.npmjs.com/package/vue-currency-filter
import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter, {
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'back',
        symbolSpacing: true
    }
);


// // https://www.npmjs.com/package/vue-confirm-dialog
// import VueConfirmDialog from "vue-confirm-dialog";
// Vue.use(VueConfirmDialog);


// https://github.com/godofbrowser/vuejs-dialog
import VuejsDialog from 'vuejs-dialog';
// include the default style
// import 'vuejs-dialog/dist/vuejs-dialog.min.css';
// Tell Vue to install the plugin.
Vue.use(VuejsDialog);
<template>
    <div class="col-lg-8 ">
        <div class="checkout__cart-content-header">
            <h4 class="checkout__cart-content-title">Sadržaj korpe</h4>
        </div>
        <div class="cart-content">
            <table class="shopping-cart-content">
                <tbody>
                    <tr class="title">
                        <td class="product-name">Naziv proizvoda</td>
                        <td class="price">Cena po kom.</td>
                        <td class="quantity-item">Količina</td>
                        <td class="total">Ukupno</td>
                    </tr>

                    <tr class="each-item" v-for="item in items" :key="item.id">
                        <td class="product-name" data-title="Naziv proizvoda">{{item.title}}</td>
                        <td class="price" data-title="Cena po kom.">{{item.price}} RSD</td>
                        <td class="quantity-item" data-title="Količina">{{item.amount}}</td>
                        <td class="total" data-title="SubTotal">{{item.price * item.amount}} RSD</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {

        props: [
            'items'
        ],
        data: function () {
            return {
                //
            };
        },
        methods: {
            //
        },
        created: function () {
            //
        }

    };

</script>

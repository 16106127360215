<template>
    <tbody class="shopping-cart-tbody">
    <tr>
        <td class="product-name" data-title="Naziv proizvoda">

            <a class="cart-row__link-text" href="#" v-on:click.stop.prevent="editSingleProductInModal(product)">{{product.title}}</a>
        </td>
        <td class="price" data-title="Cena po kom.">
            <span class="price">{{productPrice|currency}}</span>
        </td>
        <td class="quantity-item" data-title="Količina">
            {{product.amount}}
        </td>

        <td class="total" data-title="Ukupno">
            <span class="price">
                 {{productPriceTotal |currency}}
              <span class="currency">RSD</span>
            </span>
        </td>
        <td class="delete-item" data-title="Ukloni">
            <button class="btn btn-danger-revers"
                    @click="handleRemoveFromCart(product.cart_id)">
                <!-- <span class="fa fa-times"></span> -->
                <span class="icon-close"></span>
            </button>
        </td>
    </tr>
    <tr class="cart-row__prod-meta-tr">
        <td colspan="4">
            <div class="cart-row__prod-additions">
                <div v-if="selectedSize" class="cart-row__prod-meta">
                    <span class="cart-row__prod-meta-name">Veličina:</span>
                    {{selectedSize}}
                </div>
                <div v-if="selectedToppingsFree.length>0 && selectedToppingsPaid.length>0" class="cart-row__prod-meta">
                    <span class="cart-row__prod-meta-name">Dodaci:</span>
                    <span class="cart-row__topping-item" v-for="topping in selectedToppingsFree" :key="topping.id + '_name'">{{topping.name}}</span>
                    <span class="cart-row__topping-item" v-for="topping in selectedToppingsPaid" :key="topping.id + '_price'">{{topping.name}} ({{topping.price | currency}})</span>
                </div>
                <div class="cart-row__prod-meta">
                    <span class="cart-row__prod-meta-name">Napomena:</span>
                    <span v-if="!product.additions"> - </span>
                    <span v-else>{{product.additions}}</span>
                </div>
            </div>
        </td>
        <td class="delete-item edit-item" data-title="Izmeni">
            <button class="btn btn-danger-revers" @click="editSingleProductInModal(product)">
                <!-- <span class="fa fa-cog"></span> -->
                <span class="icon-cog"></span>
            </button>
        </td>
    </tr>
    </tbody>
</template>

<script>
    import {EventBus} from '../event-bus.js';

    export default {
        name: "CartRowComponent",
        props: ['item'],
        data() {
            return {
                // isShow:true,
                // itemList: this.cart_list,
                APP_URL: '',
                quantity: '',

                product: this.item[0],
                // product_price: this.item[0].price,
                productIndex: this.item[1],
                cartData: {
                    product_list: [],
                    product_count: 0,
                    total: 0
                },

                sizes: this.item[0].available_sizes,
                toppings_free: this.item[0].toppings_free,
                toppings_paid: this.item[0].toppings_paid,
            };
        },
        mounted() {
// console.log("this.item: ", this.item);



            EventBus.$on('cartDataEvent', payload => {

                this.product.amount = payload.product_list[this.productIndex].amount;
                this.product.additions = payload.product_list[this.productIndex].additions;
                this.product.selected_size = payload.product_list[this.productIndex].selected_size;
                this.product.selected_toppings = payload.product_list[this.productIndex].selected_toppings;
                this.cartData = payload;
            });
            // console.log("APP_URL: ", APP_URL);
            this.APP_URL = APP_URL;
            this.product.product_total = Math.round(this.product.product_total * 100) / 100;


            // console.log("selected_size: ",  this.product.selected_size);
        },
        computed: {
            productPriceTotal: function () {
                return this.item[0].price * this.item[0].amount;
            },
            productPrice: function () {
                return this.item[0].price;
            },
            selectedSize: function () {
                // console.log("this.sizes:", this.sizes);
                // console.log("this.product.selected_size: ", this.item[0]);
                if (this.sizes.length > 0) {
                    return this.sizes.find(x => x.id === this.product.selected_size).name;
                } else {
                    return null
                }

            },
            selectedToppingsFree: function () {
                const vm = this;
                return this.toppings_free.filter(function (o1) {
                    return vm.product.selected_toppings.some(function (o2) {
                        return o1.id === o2;
                    });
                });
            },
            selectedToppingsPaid: function () {
                const vm = this;
                return this.toppings_paid.filter(function (o1) {
                    return vm.product.selected_toppings.some(function (o2) {
                        return o1.id === o2;
                    });
                });
            }
        },
        methods: {
            editSingleProductInModal(product) {

                // this.hide();

                // open single product modal with function in front-app.js
                // this.$parent.showSingleProductModal(product);
                // console.log("product: ", product);
                EventBus.$emit('singleProductModalEditEvent', product);


            },
            increaseQuantity(id) {
                this.product.amount++;
                this.calculateProductTotal(this.product.price, this.product.amount);
                console.log('increse');
            },
            decreaseQuantity(id) {

                if (this.product.amount > 1) {
                    this.product.amount--
                } else {
                    this.product.amount = 1
                }
                this.calculateProductTotal(this.product.price, this.product.amount);

            },


            calculateProductTotal(price, amount) {

                this.product.product_total = Math.round((price * amount) * 100) / 100;
                EventBus.$emit('cartProductUpdateTotalEvent', {
                    'total': this.product.product_total,
                    'index': this.productIndex
                });

            },
            handleRemoveFromCart(id, quantity) {
                // EventBus.$emit('removedItemFromCArt', id);
                const vm = this;
                // this.$vueConfirm.confirm(
                //     {
                //         auth: false,
                //         message: `Dali ste sigurni?`,
                //         button: {
                //             no: 'Ne',
                //             yes: 'Da'
                //         }
                //     },
                //     function(confirm) {
                //         if (confirm == true) {
                //             // ... do some thing
                //
                //
                //         }
                //     }
                // )


                this.$dialog
                    .confirm('Želite da izbacite proizvod?', {
                        loader: true,
                        okText: 'Izbaci',
                        cancelText: 'Zatvori',
                    })
                    .then(function (dialog) {
                        console.log('Clicked on proceed');
                        axios.delete(APP_URL + '/api/cart/' + id)
                            .then(function (response) {
                                // vm.isShow = false;
                                vm.cartData = response.data;
                                EventBus.$emit('cartDataEvent', vm.cartData);
                                // vm.chartDropdownCount = vm.cartData.product_list.length;
                                dialog.close();

                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                    })
                    .catch(function () {
                        console.log('Clicked on cancel');
                    });

                // axios.delete(APP_URL + '/api/cart/' + id)
                //     .then(function (response) {
                //         // vm.isShow = false;
                //         vm.cartData = response.data;
                //         EventBus.$emit('cartDataEvent', vm.cartData);
                //         // vm.chartDropdownCount = vm.cartData.product_list.length;
                //
                //
                //     })
                //     .catch(function (error) {
                //         console.log(error);
                //     });
            },
        }
    }
</script>

<style scoped>

</style>

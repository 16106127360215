// window._ = require('lodash');


try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    window.Vue = require('vue');


    // reference the V4 version of the library as dependency
    // var bsn = require('bootstrap.native/dist/bootstrap-native-v4');
    var SimpleLightbox = require('simple-lightbox');


    require('just-validate/dist/js/just-validate.js');

    require('./portamento');
    // require('./jquery.enllax.js');


} catch (e) {
}


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.prototype.$http = axios;
Vue.prototype.$API_URL = window.APP_URL;

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


new SimpleLightbox({
    elements: '.imageGallery1 a',
});

require('./vue-plugins');

import CartComponent from './components/CartComponent';
import CartRowComponent from './components/CartRowComponent';
import CheckoutConfirmationComponent from './components/CheckoutConfirmationComponent';
import CheckoutFormComponent from './components/CheckoutFormComponent';
import CheckoutCartContent from './components/CheckoutCartContent';
import HeaderCartComponent from './components/HeaderCartComponent';
import SingleProductModal from './modal/SingleProductModal';

import {EventBus} from './event-bus.js';
import BootstrapVue from 'bootstrap-vue'
// import {ModalPlugin} from 'bootstrap-vue';

Vue.use(BootstrapVue);


const app = new Vue({
    el: '#app',
    components: {
        'cart-component': CartComponent,
        'head-cart': HeaderCartComponent,
        'single-product-modal': SingleProductModal,
        'checkout-confirmation': CheckoutConfirmationComponent,
        'checkout-form': CheckoutFormComponent,
        'checkout-cart-content': CheckoutCartContent,
    },
    data() {
        return {
            showModal: false,
            isShowSingleProductModal: false,
            singleProductModalData: {},
            cartData: {
                product_list: [],
                product_count: 0,
                total: 0
            },
        }
    },
    mounted() {
        const vm = this;
        EventBus.$on('singleProductModalEditEvent', data => {

            vm.showSingleProductModal(data);
            // vm.isShowSingleProductModal = true;
        });
        EventBus.$on('singleProductModalEvent', data => {
            vm.isShowSingleProductModal = data;
        });
        EventBus.$on('moduleIsOpen', data => {

            vm.showModal = data;
            vm.noScrollBody();

        });


    },
    methods: {
        noScrollBody() {
            let bodyElement = document.getElementsByTagName("BODY")[0];

            if (this.showModal) {
                bodyElement.classList.add("noscroll");
            } else {
                bodyElement.classList.remove("noscroll");
            }

        },

        showSingleProductModal(product) {

            // Modal is showing so display it
            this.isShowSingleProductModal = true;


            this.singleProductModalData = product;
            EventBus.$emit('moduleIsOpen', true);


        },
    },

});

<template>
    <div class="checkout-form content-form">
        <h4 class="main-title">Podaci za isporuku</h4>

        <form
            class="checkout-form"
            id="checkout_form"
            @submit.prevent="checkout"
            action="#"
            method="POST"
        >
            <div class="row">
                <div class="col-lg-4">
                    <div
                        class="checkout-delivery-sec"
                        v-if="!form.is_picked_up"
                    >
                        <ul>
                            <li>
                                {{ form.street }} {{ form.street_num }},
                                {{ form.city }}
                            </li>
                            <li>Sprat: {{ form.floor }}</li>
                            <li>Stan: {{ form.apartment }}</li>
                            <li>Interfon: {{ form.intercom }}</li>
                            <li v-if="form.delivery_fee > 0">
                                Cena dostave: {{ form.delivery_fee }} RSD
                            </li>
                        </ul>
                        <button
                            class="btn btn-submit btn-block  btn-primary text-uppercase "
                            @click="$bvModal.show('address-modal')"
                        >
                            Izmeni
                        </button>
                    </div>
                    <div v-else>
                        <div class="checkout-local-sec mb-2">
                            <div class="checkout-local__text">
                                <span class="icon-map-marker mr-2"></span
                                >Preuzimanje u lokalu
                            </div>
                        </div>
                        <button
                            class="btn btn-submit  btn-primary text-uppercase mb-3"
                            @click="$bvModal.show('address-modal')"
                        >
                            Izmeni
                        </button>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="delivery-address">
                        <div class="card card-dark">
                            <div class="card-header">
                                Podaci o naručiocu
                            </div>
                            <div class="card-body">
                                <div class="checkout-form__table">
                                    <div class="checkout-form__row">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div
                                                    class="checkout-form__el label-el"
                                                >
                                                    <label for="first_name">
                                                        Ime
                                                        <span>*</span></label
                                                    >
                                                </div>
                                                <div
                                                    class="checkout-form__el input-el"
                                                >
                                                    <input
                                                        type="text"
                                                        class="input-info"
                                                        v-model="
                                                            form.first_name
                                                        "
                                                        id="first_name"
                                                        maxlength="250"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div
                                                    class="checkout-form__el label-el"
                                                >
                                                    <label for="last_name"
                                                        >Prezime
                                                        <span>*</span></label
                                                    >
                                                </div>
                                                <div
                                                    class="checkout-form__el input-el"
                                                >
                                                    <input
                                                        type="text"
                                                        class="input-info"
                                                        v-model="form.last_name"
                                                        id="last_name"
                                                        maxlength="250"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="checkout-form__row">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div
                                                    class="checkout-form__el label-el"
                                                >
                                                    <label for="phone"
                                                        >Broj telefona
                                                        <span>*</span></label
                                                    >
                                                </div>
                                                <div
                                                    class="checkout-form__el input-el"
                                                >
                                                    <input
                                                        type="text"
                                                        class="input-info"
                                                        v-model="form.phone"
                                                        id="phone"
                                                        maxlength="250"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div
                                                    class="checkout-form__el label-el"
                                                >
                                                    <label for="email"
                                                        >Email
                                                        <span
                                                            v-if="
                                                                !form.is_picked_up
                                                            "
                                                            >*</span
                                                        ></label
                                                    >
                                                </div>
                                                <div
                                                    class="checkout-form__el input-el"
                                                >
                                                    <input
                                                        type="email"
                                                        class="input-info"
                                                        v-model="form.email"
                                                        id="email"
                                                        maxlength="250"
                                                        :required="
                                                            !form.is_picked_up
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="checkout-form__row">
                                        <div class="checkout-form__el label-el">
                                            <label for="company"
                                                >Naziv firme</label
                                            >
                                        </div>
                                        <div class="checkout-form__el input-el">
                                            <input
                                                type="text"
                                                class="input-info"
                                                v-model="form.company"
                                                id="company"
                                                maxlength="250"
                                            />
                                        </div>
                                    </div>
                                    <div class="checkout-form__row">
                                        <div class="checkout-form__el label-el">
                                            <label for="message"
                                                >Napomena</label
                                            >
                                        </div>
                                        <div class="checkout-form__el input-el">
                                            <textarea
                                                class="input-info"
                                                v-model="form.message"
                                                maxlength="10000"
                                                id="message"
                                                rows="2"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="spacer30"></div>
            <div class="row">
                <checkout-cart-content
                    :items="cart.product_list"
                ></checkout-cart-content>

                <div class="col-lg-4">
                    <div class="payment-form">
                        <div class="payment-form__header">
                            <h5 class="payment-form__title">Plaćanje</h5>
                        </div>
                        <div class="payment-form__body">
                            <div class="checkout-form__row">
                                <div class="checkout-form__el label-el">
                                    <label for="voucher">Vaučer kod</label>
                                </div>
                                <div class="checkout-form__el input-el">
                                    <input
                                        type="text"
                                        class="input-info"
                                        v-model="form.voucher"
                                        id="voucher"
                                        maxlength="250"
                                    />
                                </div>
                            </div>

                            <div
                                class="total-checkout d-flex justify-content-between"
                                v-if="form.delivery_fee > 0"
                            >
                                <span class="text">Stavke u korpi </span>
                                <span class="price">{{ cart.total }} RSD</span>
                            </div>
                            <div
                                class="total-checkout d-flex justify-content-between"
                                v-if="form.delivery_fee > 0"
                            >
                                <span class="text">Cena dostave </span>
                                <span class="price"
                                    >{{ form.delivery_fee }} RSD</span
                                >
                            </div>
                            <div
                                class="total-checkout d-flex justify-content-between"
                            >
                                <span class="text">Ukupno </span>
                                <span class="price"
                                    >{{ cart.total + form.delivery_fee }} RSD
                                    <span
                                        v-if="
                                            !form.is_picked_up &&
                                                form.delivery_fee == 0
                                        "
                                        >+ Dostava</span
                                    >
                                </span>
                            </div>
                        </div>
                        <div class="alert alert-warning" v-if="warning_text">
                            {{ warning_text }}
                        </div>
                        <div class="alert alert-success" v-if="is_checked_out">
                            Uspešno ste obavili kupovinu!
                        </div>

                        <div class="group-button mb-4 mb-md-0">
                            <button
                                class="btn btn-block btn-primary text-uppercase"
                                v-if="!is_checked_out"
                                :disabled="is_processing"
                            >
                                {{ button_text }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div id="map" class="d-none"></div>

        <checkout-address-modal
            @setaddress="set_address"
        ></checkout-address-modal>
    </div>
</template>

<script>
const dostavau_api = window.APP_URL + "/api/dostavau/zone-for-point";
const google = window.google;

import CheckoutCartContent from "../components/CheckoutCartContent";
import CheckoutAddressModal from "../modal/CheckoutAddressModal";

export default {
    props: ["cart"],
    data: function() {
        return {
            form: {
                is_picked_up: 0,
                delivery_fee: 0,
                longitude: null,
                latitude: null,
                street: null,
                street_num: null,
                city: null,
                floor: null,
                apartment: null,
                intercom: null,
                voucher: null
            },
            is_processing: false,
            is_checked_out: false,
            warning_text: null
        };
    },
    components: {
        "checkout-cart-content": CheckoutCartContent,
        "checkout-address-modal": CheckoutAddressModal
    },
    computed: {
        button_text: function() {
            return this.is_processing
                ? "Molim sačekajte..."
                : "Nastavi sa naručivanjem";
        }
    },
    methods: {
        checkout: function() {
            this.is_processing = true;
            this.warning_text = null;

            axios
                .post(APP_URL + "/placanje", this.get_data())
                .then(response => {
                    this.success_message = "Uspešno ste obavili kupovinu!";
                    // if (!this.form.is_picked_up) {
                    //     setTimeout(function() {
                    //         window.location.href =
                    //             APP_URL + "/placanje/potvrda";
                    //     }, 2000);
                    // }

                     setTimeout(function() {
                            window.location.href =
                                APP_URL + "/placanje/potvrda";
                        }, 1000);


                    this.is_checked_out = true;
                })
                .catch(error => {
                    this.is_processing = false;

                    var data = error.response.data;
                    if (data.errors && data.errors.voucher) {
                        this.warning_text =
                            "Vaučer ne postoji ili je već upotrebljen.";
                    } else
                        this.warning_text =
                            "Došlo je do greške. Proverite da li ste ispravno popunili obavezna polja.";
                });
        },
        get_data: function() {
            if (this.form.is_picked_up) {
                return {
                    is_picked_up: 1,
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    voucher: this.form.voucher,
                    message: this.form.message,
                    phone: this.form.phone
                };
            } else {
                return this.form;
            }
        },
        set_address: function(data) {
            this.form.is_picked_up = data.is_picked_up;
            this.form.delivery_fee = data.delivery_fee;
            this.form.longitude = data.longitude;
            this.form.latitude = data.latitude;
            this.form.street = data.street;
            this.form.street_num = data.street_number;
            this.form.city = data.city;
            this.form.floor = data.floor;
            this.form.apartment = data.apartment;
            this.form.intercom = data.intercom;
            this.$bvModal.hide("address-modal");
        }
    },
    mounted: function() {
        this.$bvModal.show("address-modal");
    },
    watch: {
        //
    }
};
</script>

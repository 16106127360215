<template>
    <div class="header-cart">
        <div class="header-cart__box  d-flex">

            <button class="btn head-cart__btn" type="button" @click="goToCartPage()">
                <span class="head-cart__btn-icon icon-shopping-basket mr-2"></span>
                <span class="head-cart__val  ">Vaša korpa {{cartData.total | currency}}</span>
                <span class="head-cart__count ">{{cartData.count}}</span>
            </button>
        </div>
    </div>
</template>

<script>

    import {EventBus} from '../event-bus.js';

    export default {
        name: "HeaderCartComponent",

        data() {
            return {

                APP_URL: '',
                cartData: {
                    count: 0,
                    total: 0
                },
            }
        },

        created() {
            EventBus.$on('cartDataEvent', payload => {
                this.cartData = payload;
            });

        },

        mounted() {


            this.APP_URL = window.APP_URL;
            const vm = this;

            this.$http.get(this.APP_URL + '/api/cart')
                .then(response => {
                    vm.cartData = response.data;
                    EventBus.$emit('cartDataEvent', response.data);
                }).catch(error => {
                // console.log(error);
            });


        },

        methods: {
            showCartModal() {
                this.$parent.showCartModal();
            },
            goToCartPage() {
                window.location.href = window.APP_URL + '/korpa'
            },
        }
    }
</script>

<style scoped>

</style>
